export const sendEvent = (action, label, value) => {
    if (typeof window !== "undefined" && window["gtag"]) {
        window.gtag("event", action, {label, value});
    }
};


export const sendGoogleAdsEvent = () => {
    if (typeof window !== "undefined" && window["gtag"]) {
 				window.gtag('event', 'conversion', {'send_to': 'AW-11252391192/gsRWCKqTxbgYEJi6x_Up'});
    }
};
