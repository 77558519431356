import { Link } from "gatsby";
import React from "react";

import "./slogan-flipper.scss";

export const SloganFlipper = () => {
    return null;
    // return (
    //     <div className="slogan-flipper-container">
    //         <h1>¿algo no va bien con tu abogado?</h1>
    //         <div id="flip">
    //             <div>
    //                 <div>mala praxis profesional</div>
    //             </div>
    //             <div>
    //                 <div>indemnización por negligencia</div>
    //             </div>
    //             <div>
    //                 <div>estudiamos tu reclamación</div>
    //             </div>
    //         </div>
    //         <h1>...averígualo con <Link to="/nuestros-servicios/">nosotros!</Link></h1>
    //     </div>
    // );
};
