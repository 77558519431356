import React from "react";
import { button } from "./cta-button.module.scss"

export default function CtaButton({ children, onclick, disabled }) {
    return (
        <React.Fragment>
            <button
                className={button}
                onClick={onclick}
                disabled={disabled}
            >
                {children}
            </button>
        </React.Fragment>
    );
}
