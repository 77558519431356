import React, { useContext, useState } from "React"
import {
  showModalContext,
  useGlobalState,
} from "../../utils/globalStateProvider"
import ContactForm from "../contact-form/contact-form"

import "./full-screen-modal.scss"
import { Link } from "gatsby"

export const FullScreenModal = ({ show, onClose }) => {
  // const [showModal, setShowModal] = useState(false);
  const [state, myDispatch] = useGlobalState()

  return (
    <div
      id="myModal"
      className="modal"
      style={{
        display: state.showModal ? "block" : "none",
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <span
            className="close"
            onClick={() => {
              // onClose()
              // setShowModal(false);
              myDispatch({ showModal: false })
            }}
          >
            &times;
          </span>
        </div>
        <div className="modal-body">
          <h2 className="message">
            Este servicio en estos momentos no está disponible en formato online
          </h2>
          <p className="subnote">
            {" "}
            Puede ponerse en contacto con nosotros a través del{" "}
            <strong>
              <Link to="/contacto/">formulario de contacto</Link>
            </strong>
            . Disculpe las molestias
          </p>
          {/* <ContactForm note="Este servicio en estos momentos no está disponible en formato online. Si nos facilita su correo electrónico nos pondremos en contacto con usted lo antes posible. Disculpe las molestias" /> */}
        </div>
      </div>
    </div>
  )
}
