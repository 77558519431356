import React from "react";
import { secondaryButton } from "./cta-button.module.scss"

export default function CtaButtonSecondary({ children, onclick, disabled }) {
    return (
        <React.Fragment>
            <button
                className={secondaryButton}
                onClick={onclick}
                disabled={disabled}
            >
                {children}
            </button>
        </React.Fragment>
    );
}
