import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import CtaButtonSecondary from "../cta-button/cta-button-secondary"
import { TickMark } from "../tick-mark/tick-mark"
import Fade from "react-reveal/Fade"

import "./service-card.scss"
import { useGlobalState } from "../../utils/globalStateProvider"
import { sendEvent } from "../../utils/analyticsService"

const renderFeature = (index, item) => {
  return (
    <li className="service-feature-item" key={`feat-${index}`}>
      <TickMark />
      <div className="text" dangerouslySetInnerHTML={{ __html: item }}></div>
    </li>
  )
}

export const ServiceCard = ({
  title,
  featureList,
  ctaLink,
  featuredImage,
  isFree,
  showModalNoAvailable = true,
}) => {
  const [status, setStatus] = useState("")
  const [state, myDispatch] = useGlobalState()

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setStatus("items-added")
  })

  // console.log('JES featureList',featureList);
  return (
    <div className={`service-card-container ${isFree ? "free" : ""}`}>
      <div className="header">
        {featuredImage}
        <h3>{title}</h3>
      </div>

      {featureList && (
        // <Fade top cascade spy={status === "items-added"}>
        <ul className="service-feature-list">
          {featureList.map((item, index) => {
            return renderFeature(index, item)
          })}
        </ul>
        // </Fade>
      )}
      <CtaButtonSecondary
        onclick={() => {
          sendEvent(`Click en servicio : ${ctaLink.substring(1)}`)

          if (showModalNoAvailable) {
            // if (ctaLink !== "/cuestionario-autovaloracion") {
            myDispatch({ showModal: true })
          } else {
            navigate(ctaLink)
          }
        }}
      >
        + Info
      </CtaButtonSecondary>
    </div>
  )
}
